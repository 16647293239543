<div class="main-body privacy-policy">
  <a [routerLink]="['/']" class="return-anchor top"><i class="fa-solid fa-arrow-turn-down-left"></i>Powrót do strony głównej</a>

  <div class="logo">
    <img [src]="'./assets/ui/logo.png'">
  </div>

  <div class="content">
    <h2>ZASADY PRZETWARZANIA DANYCH OSOBOWYCH
      W
      <a class="ignore-weight" href="WWW.TRAINAPP.PL">„TRAINAPP”</a></h2>

    <p class="non-padded">Poniżej przedstawiamy informacje o tym w jaki sposób oraz w jakich celach przetwarzamy Twoje
      dane osobowe w związku z korzystaniem z „TrainApp”, czyli usługi cyfrowej pozwalającej m.in. na
      rejestrację swojego udziału w targach produktowych.<br>
      TrainApp dostępna jest zarówno w formie aplikacji (dla urządzeń z systemem Android), jak i strony
      internetowej. </p>
    <p class="bold padded"><span class="bullet">1.</span>Dane </p>
    <p class="non-padded">Współadministratorami Twoich danych osobowych są spółki z Grupy Media Expert. Grupę Media
      Expert tworzą spółki odpowiedzialne za prowadzenie sprzedaży w sklepach Media Expert, w tym
      również na mediaexpert.pl oraz w aplikacji Media Expert, a także za dostawę towarów na
      terytorium Polski (dalej: Media Expert).<br><br>
      Jeżeli chciałbyś się z nami skontaktować, wystarczy, że napiszesz do nas mail na adres
      <a href="mailto:inspektor@me.pl">inspektor@me.pl</a> lub prześlesz list na adres: „Media Expert” TERG S.A., ul. Za Dworcem 1D, 77-
      400 Złotów z dopiskiem „Dane osobowe”. Kontakt z nami jest łatwy, ponieważ w Media Expert
      wyznaczyliśmy jeden punkt kontaktowy dla wszystkich spraw dotyczących danych osobowych.<br><br>
      Ochrona Twoich danych osobowych jest naszym priorytetem. Dlatego, aby ją dodatkowo
      wzmocnić, wyznaczyliśmy w Media Expert Inspektora Ochrony Danych (dalej: Inspektor). Naszym
      Inspektorem jest Marcin Sikora (kontakt: <a href="mailto:inspektor@me.pl">inspektor@me.pl</a>).</p>
    <p class="bold padded"><span class="bullet">2.</span>Zbieranie danych </p>
    <p class="non-padded">Sposób zbierania danych zależy od Twojego statusu.<br><br>
      Wyróżniamy:</p>
    <p><span class="bullet">a.</span>Uczestnika,</p>
    <p><span class="bullet">b.</span>Gościa ME,</p>
    <p><span class="bullet">c.</span>Gościa zewnętrznego,</p>
    <p><span class="bullet">d.</span>Trenera.</p>
    <p class="non-padded">Jeśli jesteś gościem ME, gościem zewnętrznym albo trenerem, to wszystkie dane otrzymujemy
      bezpośrednio od Ciebie. W trakcie rejestracji podajesz nam: </p>
    <p><span class="bullet">a.</span>HR ID, imię, nazwisko, dział, email – dot. gościa ME,</p>
    <p><span class="bullet">b.</span>imię, nazwisko, email oraz firmę – dot. gościa zewnętrznego i trenera.</p>
    <p class="non-padded">Dane do TrainApp mógł też wprowadzić Dział Szkoleń, który odpowiada za utworzenie kont
      pracowników ME obowiązanych do zrealizowania określonych aktywności podczas targów
      (uczestnik). </p>
    <p class="non-padded">Podanie danych osobowych jest konieczne, aby korzystać z TrainApp.</p>
    <p class="non-padded">W aplikacji oraz na stronie internetowej stosujemy wyłącznie pliki cookies niezbędne.</p>
    <p class="non-padded">Twoje dane nie będą podlegały profilowaniu ani zautomatyzowanemu podejmowaniu decyzji.</p>

    <p class="bold padded"><span class="bullet">3.</span>Cele przetwarzania i podstawa prawna </p>
    <p class="non-padded">Dane przetwarzamy w celu świadczenia usługi TrainApp, która jest usługą dedykowaną dla
      odwiedzających targi produktowe. Przetwarzamy dane, gdyż jest to niezbędne do wykonania
      umowy o prowadzenie konta w TrainApp.</p>
    <p class="non-padded">Rejestracja w TrainApp daje możliwość wejścia do przestrzeni targowej i zapoznania się z agendą
      targów. </p>
    <p class="non-padded">W przypadku uczestników TrainApp służy również do wyznaczania zadań do zrealizowania i
      weryfikacji wykonania tych zadań np. poprzez generowanie wyników osiągniętych w testach przez
      poszczególnych uczestników.</p>

    <p class="bold padded"><span class="bullet">4.</span>Okres przetwarzania danych </p>
    <p class="non-padded">Przetwarzamy dane osobowe do czasu korzystania przez Ciebie z TrainApp</p>
    <p class="non-padded">Po tym okresie Twoje dane możemy przechowywać dlatego, że:</p>
    <p><span class="bullet dot">•</span>dane mogą być dla nas niezbędne do wykazania, że nasze działanie było zgodne z prawem
      (np. dane o wyrażonej przez Ciebie zgodzie na kontakt marketingowy);</p>
    <p><span class="bullet dot">•</span>nie minął jeszcze termin przedawnienia Twoich roszczeń.</p>
    <p class="bold padded"><span class="bullet">5.</span>Odbiorcy danych</p>
    <p class="non-padded">Twoje dane mogą być ujawniane innym podmiotom tylko wówczas, gdy jest to niezbędne do
      zrealizowania działań związanych z funkcjonowaniem TrainApp. </p>
    <p class="non-padded">Twoje dane możemy ujawniać firmą świadczącym usługi IT, dzięki czemu możemy trzymywać
      oprogramowanie. </p>
    <p class="non-padded">W każdym przypadku, gdy Twoje dane są ujawniane innym podmiotom, dzieję się to w ramach
      obowiązujących przepisów. Jeśli partnerzy lub usługodawcy przetwarzają Twoje dane w imieniu
      Media Expert, muszą zapewnić gwarancje wdrożenia rozwiązań technicznych i organizacyjnych,
      które będą chroniły Twoje dane osobowe i spełniały wymogi ogólnego Rozporządzenia o Ochronie
      Danych Osobowych (RODO).</p>
    <p class="bold padded"><span class="bullet">6.</span>Prawa podmiotów danych</p>
    <p class="non-padded">Prawo ochrony danych osobowych daje Ci szereg praw, z których możesz skorzystać w dowolnej
      chwili. Twoje prawa obejmują:</p>
    <p><span class="bullet">a.</span>prawo dostępu do treści swoich danych osobowych; </p>
    <p><span class="bullet">b.</span>prawo do poprawiania danych;
    </p>
    <p><span class="bullet">c.</span>prawo do ograniczenia przetwarzania danych;</p>
    <p><span class="bullet">d.</span>prawo do żądania usunięcia danych;</p>
    <p><span class="bullet">e.</span>prawo do przenoszenia danych do innego administratora danych.</p>
    <p class="non-padded">Uprawnienia, o których mowa powyżej, możesz wykonywać poprzez kontakt pod adresem e-mail
      <a href="mailto:inspektor@me.pl">inspektor@me.pl</a> lub pisząc na adres „Media Expert” TERG S.A., ul. Za Dworcem 1D, 77-400
      Złotów, z dopiskiem „Dane osobowe”. </p>
    <p class="non-padded">Jeżeli uznasz, że w jakikolwiek sposób naruszone zostały reguły przetwarzania Twoich danych
      osobowych, masz prawo do złożenia skargi bezpośrednio do organu nadzoru (Prezes Urzędu
      Ochrony Danych Osobowych, ul. Stawki 2, 00-193 Warszawa). W ramach wykonania tego
      uprawnienia powinieneś podać pełny opis zaistniałej sytuacji oraz wskazać jakie działanie
      uznajesz za naruszające Twoje prawa lub wolności. Skargę należy złożyć bezpośrednio do organu
      nadzoru.
    </p>
    <p class="bold padded"><span class="bullet">7.</span>Transfer danych poza EOG</p>
    <p class="non-padded">Twoje dane nie będą przekazywane do państwa trzeciego lub organizacji międzynarodowej.
    </p>
    <p class="bold padded"><span class="bullet">8.</span>Lista spółek z Grupy Media Expert</p>
    <p class="non-padded">TERG S.A., ul. Za Dworcem 1D, 77-400 Złotów, REGON 570217011, NIP: 7671004218, KRS
      0000427063 (usługodawca);
    </p>
    <p class="non-padded">ME M01 Sp. z o.o., ul. Za Dworcem 1D, 77-400 Złotów, REGON: 384110047, NIP: 7671714109,
      KRS 0000798896;
    </p>
    <p class="non-padded">ME M02 Sp. z o.o., ul. Za Dworcem 1D, 77-400 Złotów, REGON: 384099558, NIP: 7671714049,
      KRS 0000798323;
    </p>
    <p class="non-padded">ME M03 Sp. z o.o., ul. Za Dworcem 1D, 77-400 Złotów, REGON: 384099564, NIP: 7671714055,
      KRS 0000798325;
    </p>
    <p class="non-padded">ME M04 Sp. z o.o., ul. Za Dworcem 1D, 77-400 Złotów, REGON: 384099601, NIP: 7671714061,
      KRS 0000798326;
    </p>
    <p class="non-padded">ME M05 Sp. z o.o., ul. Za Dworcem 1D, 77-400 Złotów, REGON: 384110099, NIP: 7671714115,
      KRS 0000798899;
    </p>
    <p class="non-padded">ME M06 Sp. z o.o., ul. Za Dworcem 1D, 77-400 Złotów, REGON: 384099535, NIP: 7671714026,
      KRS 0000798327;
    </p>
    <p class="non-padded">ME M07 Sp. z o.o., ul. Za Dworcem 1D, 77-400 Złotów, REGON: 384057459, NIP: 7671713943,
      KRS 0000797868;
    </p>
    <p class="non-padded">ME M08 Sp. z o.o., ul. Za Dworcem 1D, 77-400 Złotów, REGON: 690457914, NIP: 8132199474,
      KRS 0000108761;
    </p>
    <p class="non-padded">ME M09 Sp. z o.o., ul. Za Dworcem 1D, 77-400 Złotów, REGON: 384099417, NIP: 7671714032,
      KRS 0000798328;
    </p>
    <p class="non-padded">ME M10 Sp. z o.o., ul. Za Dworcem 1D, 77-400 Złotów, REGON: 384099268, NIP: 7671713995,
      KRS 0000798330;
    </p>
    <p class="non-padded">ME M11 Sp. z o.o., ul. Za Dworcem 1D, 77-400 Złotów, REGON: 384091083, NIP: 7671713972,
      KRS 0000797960;
    </p>
    <p class="non-padded">ME M12 Sp. z o.o., ul. Za Dworcem 1D, 77-400 Złotów, REGON: 384099386, NIP: 7671714003,
      KRS 0000798331.
    </p>
    <p class="bold padded"><span class="bullet">9.</span>Pobieranie aplikacji w sklepie z aplikacjami </p>
    <p class="non-padded">Podczas pobierania aplikacji operatorzy sklepu z aplikacjami (Google Play) automatycznie
      przetwarza dane w szczególności takie jak:
    </p>
    <p><span class="bullet dot">•</span>nazwa użytkownika w sklepie z aplikacjami,</p>
    <p><span class="bullet dot">•</span>adres e-mail zapisany w sklepie z aplikacjami,</p>
    <p><span class="bullet dot">•</span>numer klienta z Twojego konta w sklepie z aplikacjami,
    </p>
    <p><span class="bullet dot">•</span>data i czas pobrania,</p>
    <p><span class="bullet dot">•</span>indywidualny numer identyfikacyjny urządzenia.</p>
    <p class="non-padded">Nie mamy wpływu na to przetwarzanie danych i nie ponosimy za nie odpowiedzialności. Więcej
      informacji w tym zakresie możesz znaleźć w Polityce prywatności danego sklepu z aplikacjami:
    </p>
    <p><span class="bullet dot">•</span>Google Play Store: <a href="https://policies.google.com/privacy?hl=pl">https://policies.google.com/privacy?hl=pl</a></p>
<!--    <p><span class="bullet">1.1</span>Współadministratorami Twoich danych osobowych są spółki spółki Grupy Media Expert (współadministratorzy). Dalej nazywamy nas po prostu „Media Expert”. Media Expert tworzą spółki odpowiedzialne za prowadzenie sprzedaży w sklepach pod marką „Media Expert”, a także w sklepie internetowym <a href="www.mediaexpert.pl">www.mediaexpert.pl</a>. Są</p>-->
<!--    <p><span class="bullet">1.1.1</span><span class="bold">TERG S.A.</span>, ul. Za Dworcem 1D, 77-400 Złotów, REGON 570217011, NIP:     7671004218, KRS 0000427063;</p>-->
<!--    <p><span class="bullet">1.1.2</span><span class="bold">ME M01 sp. z o. o.</span> z siedzibą w Złotowie (77-400), ul. Za Dworcem 1D, NIP: 7671714109, REGON: 570217011, wpisana do rejestru przedsiębiorców prowadzonego przez Sąd Rejonowy Poznań - Nowe Miasto i Wilda w Poznaniu, IX Wydział Gospodarczy Krajowego Rejestru Sądowego pod numerem KRS 427063, o kapitale zakładowym opłaconym w całości w wysokości 50.000 złotych.</p>-->
<!--    <p><span class="bullet">1.1.3</span><span class="bold">ME M02 sp. z o. o.</span> z siedzibą w Złotowie (77-400), ul. Za Dworcem 1D, NIP: 7671714049, REGON: 384099558, wpisana do rejestru przedsiębiorców prowadzonego przez Sąd Rejonowy Poznań - Nowe Miasto i Wilda w Poznaniu, IX Wydział Gospodarczy Krajowego Rejestru Sądowego pod numerem KRS 798323, o kapitale zakładowym opłaconym w całości w wysokości 50.000 złotych. </p>-->
<!--    <p><span class="bullet">1.1.4</span><span class="bold">ME M03 sp. z o. o.</span> z siedzibą w Złotowie (77-400), ul. Za Dworcem 1D, NIP: 7671714055, REGON: 384099564, wpisana do rejestru przedsiębiorców prowadzonego przez Sąd Rejonowy Poznań - Nowe Miasto i Wilda w Poznaniu, IX Wydział Gospodarczy Krajowego Rejestru Sądowego pod numerem KRS 798325, o kapitale zakładowym opłaconym w całości w wysokości 50.000 złotych. </p>-->
<!--    <p><span class="bullet">1.1.5</span><span class="bold">ME M04 sp. z o. o.</span> z siedzibą w Złotowie (77-400), ul. Za Dworcem 1D, NIP: 7671714061, REGON: 384099601, wpisana do rejestru przedsiębiorców prowadzonego przez Sąd Rejonowy Poznań - Nowe Miasto i Wilda w Poznaniu, IX Wydział Gospodarczy Krajowego Rejestru Sądowego pod numerem KRS 798326, o kapitale zakładowym opłaconym w całości w wysokości 50.000 złotych.  </p>-->
<!--    <p><span class="bullet">1.1.6</span><span class="bold">ME M05 sp. z o. o.</span> z siedzibą w Złotowie (77-400), ul. Za Dworcem 1D, NIP: 7671714115, REGON: 384110099, wpisana do rejestru przedsiębiorców prowadzonego przez Sąd Rejonowy Poznań - Nowe Miasto i Wilda w Poznaniu, IX Wydział Gospodarczy Krajowego Rejestru Sądowego pod numerem KRS 798899, o kapitale zakładowym opłaconym w całości w wysokości 50.000 złotych.  </p>-->
<!--    <p><span class="bullet">1.1.7</span><span class="bold">ME M06 sp. z o. o.</span> z siedzibą w Złotowie (77-400), ul. Za Dworcem 1D, NIP: 7671714026, REGON: 384099535, wpisana do rejestru przedsiębiorców prowadzonego przez Sąd Rejonowy Poznań - Nowe Miasto i Wilda w Poznaniu, IX Wydział Gospodarczy Krajowego Rejestru Sądowego pod numerem KRS 798327, o kapitale zakładowym opłaconym w całości w wysokości 50.000 złotych. </p>-->
<!--    <p><span class="bullet">1.1.8</span><span class="bold">ME M07 sp. z o. o.</span> z siedzibą w Złotowie (77-400), ul. Za Dworcem 1D, NIP: 7671713943, REGON: 384057459, wpisana do rejestru przedsiębiorców prowadzonego przez Sąd Rejonowy Poznań - Nowe Miasto i Wilda w Poznaniu, IX Wydział Gospodarczy Krajowego Rejestru Sądowego pod numerem KRS 797868, o kapitale zakładowym opłaconym w całości w wysokości 50.000 złotych.  </p>-->
<!--    <p><span class="bullet">1.1.9</span><span class="bold">ME M08 sp. z o. o.</span> z siedzibą w Złotowie (77-400), ul. Za Dworcem 1D, NIP: 8132199474, REGON: 690457914, wpisana do rejestru przedsiębiorców prowadzonego przez Sąd Rejonowy Poznań - Nowe Miasto i Wilda w Poznaniu, IX Wydział Gospodarczy Krajowego Rejestru Sądowego pod numerem KRS 108761, o kapitale zakładowym opłaconym w całości w wysokości 50.000 złotych.  </p>-->
<!--    <p><span class="bullet">1.1.10</span><span class="bold">ME M09 sp. z o. o.</span> z siedzibą w Złotowie (77-400), ul. Za Dworcem 1D, NIP: 7671714032, REGON: 384099417, wpisana do rejestru przedsiębiorców prowadzonego przez Sąd Rejonowy Poznań - Nowe Miasto i Wilda w Poznaniu, IX Wydział Gospodarczy Krajowego Rejestru Sądowego pod numerem KRS 798328, o kapitale zakładowym opłaconym w całości w wysokości 50.000 złotych.  </p>-->
<!--    <p><span class="bullet">1.1.11</span><span class="bold">ME M10 sp. z o. o.</span> z siedzibą w Złotowie (77-400), ul. Za Dworcem 1D, NIP: 7671713995, REGON: 384099268, wpisana do rejestru przedsiębiorców prowadzonego przez Sąd Rejonowy Poznań - Nowe Miasto i Wilda w Poznaniu, IX Wydział Gospodarczy Krajowego Rejestru Sądowego pod numerem KRS 798330, o kapitale zakładowym opłaconym w całości w wysokości 50.000 złotych.  </p>-->
<!--    <p><span class="bullet">1.1.12</span><span class="bold">ME M11 sp. z o. o.</span> z siedzibą w Złotowie (77-400), ul. Za Dworcem 1D, NIP: 7671713972, REGON: 384091083, wpisana do rejestru przedsiębiorców prowadzonego przez Sąd Rejonowy Poznań - Nowe Miasto i Wilda w Poznaniu, IX Wydział Gospodarczy Krajowego Rejestru Sądowego pod numerem KRS 797960, o kapitale zakładowym opłaconym w całości w wysokości 50.000 złotych.  </p>-->
<!--    <p><span class="bullet">1.1.13</span><span class="bold">ME M12 sp. z o. o.</span> z siedzibą w Złotowie (77-400), ul. Za Dworcem 1D, NIP: 7671714003, REGON: 384099386, wpisana do rejestru przedsiębiorców prowadzonego przez Sąd Rejonowy Poznań - Nowe Miasto i Wilda w Poznaniu, IX Wydział Gospodarczy Krajowego Rejestru Sądowego pod numerem KRS 798331, o kapitale zakładowym opłaconym w całości w wysokości 50.000 złotych.  </p>-->
<!--    <p class="non-padded">Jeżeli chciałbyś się z nami skontaktować, wystarczy, że napiszesz do nas e-mail na adres inspektor@me.pl lub prześlesz list na adres: „Media Expert” TERG S.A., ul. Za Dworcem 1D, 77-400 Złotów z dopiskiem „Dane osobowe”. Kontakt ws. ochrony danych osobowych jest łatwy, ponieważ w Media Expert wyznaczyliśmy jeden punkt kontaktowy dla wszystkich zagadnień dotyczących danych osobowych. </p>-->
<!--    <p><span class="bullet">1.2</span>Ochrona Twoich danych osobowych jest priorytetem Media Expert. Dlatego, aby ją dodatkowo wzmocnić, powołaliśmy Inspektora Ochrony Danych Osobowych. Osobą odpowiedzialną za sprawowanie tej funkcji jest Marcin Sikora. Adres kontaktowy do Inspektora to: inspektor@me.pl.</p>-->
<!--    <p class="bold"><span class="bullet">2.0</span>W jaki sposób zbieramy twoje dane osobowe?</p>-->
<!--    <p><span class="bullet">2.1</span>Dane Uczestników Akcji zbieramy od nich. Uczestnicy podają nam swoje dane osobowe zgłaszając udział w Akcji oraz korzystając z Platformy. Ponadto, ustalamy wyniki realizacji przez Uczestników zadań wyznaczanych im w ramach Akcji na podstawie systemów sprzedażowych oraz rozliczeniowych stosowanych w Media Expert. Dzięki temu, że przetwarzamy dane osobowe Uczestników (np. numer kadrowy, adres e-mail, wyniki realizacji zadań, dane adresowe do wysyłki nagrody), możemy odpowiednio zidentyfikować Uczestników, a także prawidłowo i sprawnie przeprowadzić dla nich Akcję.</p>-->
<!--    <p><span class="bullet">2.2</span>Podanie danych osobowych jest konieczne, aby zgłosić udział w Akcji. Jeśli nie podasz nam swoich danych, to niestety nie będziemy mogli przyjąć Twojego zgłoszenia do Akcji.</p>-->
<!--    <p class="bold"><span class="bullet">3.0</span>W jakim celu przetwarzamy twoje dane osobowe?</p>-->

<!--    <p class="non-padded">Twoje dane osobowe są przetwarzane w celu przeprowadzenia Akcji, zgodnie z jej regulaminem. Obejmuje to w szczególności zapewnienie Tobie możliwości zgłoszenia udziału w Akcji, a także prowadzenie z Tobą bieżącego kontaktu ws. Akcji i przekazywania informacji o wyznaczonych zadaniach lub możliwości uzyskania dodatkowych punktów lub nagród w Akcji. Jeśli uzyskasz nagrodę w Akcji, Twoje dane osobowe będą przetwarzane również w celu wydania Tobie nagrody. </p>-->
<!--    <p class="bold"><span class="bullet">4.0</span>Pliki cookies</p>-->
<!--    <p class="non-padded">W przypadku korzystania przez Ciebie z Platformy w wersji webowej (strona internetowa <a href="www.misjaexpert.pl">www.misjaexpert.pl</a>) przetwarzamy informacje o sposobie korzystania przez Ciebie z tej strony, jeżeli wyrazisz zgodę na pliki cookies, w tym numer IP oraz informacje o urządzeniu, z którego korzystasz (np. przeglądarka, rozdzielczość ekranu). Pliki cookies to niewielkie pliki tekstowe powszechnie stosowane w Internecie, przechowywane na Twoim urządzeniu. Dzięki plikom cookies możemy:</p>-->
<!--    <p><span class="bullet">4.1</span>lepiej prezentować Tobie nasze treści – sprawdzamy, jakie komunikaty z naszej strony były już Tobie prezentowane, aby ich nie powtarzać oraz prezentować treści, które bardziej pasują do Twoich zainteresowań;</p>-->
<!--    <p><span class="bullet">4.2</span>dbać o sprawne oraz bezpieczne działanie naszej strony – zabezpieczamy ją np. przed działaniem robotów (programów, które automatycznie badają zawartości naszej strony);</p>-->
<!--    <p><span class="bullet">4.3</span>sprawdzać statystyki dotyczące korzystania z naszej strony – budujemy zbiorcze, anonimowe statystyki odwiedzin i działań w naszym serwisie.</p>-->
<!--    <p class="non-padded">W każdej chwili możesz zmienić zasady wykorzystywania cookies na Twoim urządzeniu, wybierając odpowiednią opcję w ustawieniach przeglądarki:</p>-->
<!--    <p><span class="bullet">4.4</span>dla przeglądarki Mozilla Firefox pod adresem: <a href="https://support.mozilla.org/pl/kb/blokowanie-ciasteczek">https://support.mozilla.org/pl/kb/blokowanie-ciasteczek</a>,</p>-->
<!--    <p><span class="bullet">4.5</span>dla przeglądarki Internet Explorer pod adresem: <a href="http://windows.microsoft.com/pl-pl/windows-vista/block-or-allow-cookies">http://windows.microsoft.com/pl-pl/windows-vista/block-or-allow-cookies</a>,</p>-->
<!--    <p><span class="bullet">4.6</span>dla przeglądarki Microsoft Edge pod adresem: <a href="https://privacy.microsoft.com/pl-PL/windows-10-microsoft-edge-and-privacy">https://privacy.microsoft.com/pl-PL/windows-10-microsoft-edge-and-privacy</a>,</p>-->
<!--    <p><span class="bullet">4.7</span>dla przeglądarki Google Chrome pod adresem: <a href="https://support.google.com/chrome/answer/95647?hl=pl">https://support.google.com/chrome/answer/95647?hl=pl</a>,</p>-->
<!--    <p><span class="bullet">4.8</span>dla przeglądarki Opera pod adresem: <a href="http://help.opera.com/Linux/9.22/pl/cookies.html">http://help.opera.com/Linux/9.22/pl/cookies.html</a>,</p>-->
<!--    <p><span class="bullet">4.9</span>dla przeglądarki Safari pod adresem: <a href="http://safari.helpmax.net/pl/ochrona-i-prywatnosc/zarzadzanie-plikami-cookie/">http://safari.helpmax.net/pl/ochrona-i-prywatnosc/zarzadzanie-plikami-cookie/</a>.</p>-->
<!--    <p class="non-padded">Zablokowanie plików cookies może wpłynąć negatywnie na korzystanie ze strony internetowej <a href="www.misjaexpert.pl">www.misjaexpert.pl</a>.</p>-->
<!--    <p class="bold"><span class="bullet">5.0</span>Na jakiej podstawie prawnej przetwarzane są twoje dane?</p>-->
<!--    <p><span class="bullet">5.1</span>Twoje dane osobowe podawane w związku z udziałem w Akcji przetwarzane są dlatego, że wyrażasz na to zgodę. Uzyskania zgody na przetwarzanie Twoich danych osobowych w celu przeprowadzenia Akcji wymagają od nas przepisy Kodeksu pracy.</p>-->
<!--    <p><span class="bullet">5.2</span>Zgodnie z przepisami o ochronie danych osobowych, za zgodę na przetwarzanie danych można uznać wyraźne działanie potwierdzające chęć (przyzwolenie), aby dane osobowe były przetwarzane w określonym celu. Za takie wyraźne działanie potwierdzające uznajemy zgłoszenie udziału w Akcji, ponieważ takie zgłoszenie jest zawsze dobrowolne, konkretne, świadome i jednoznaczne.</p>-->
<!--    <p><span class="bullet">5.3</span>Pamiętaj, że zgoda na przetwarzanie danych osobowych w celu przeprowadzenia Akcji może być wycofana w dowolnej chwili, np. poprzez e-mail na adres info@misjaexpert.pl. Brak zgody (czyli niezgłoszenie udziału w Akcji) lub jej wycofanie, w żadnym wypadku nie będą podstawą do jakiegokolwiek niekorzystnego traktowania Ciebie jako pracownika i nie będą powodowały wobec Ciebie jakichkolwiek negatywnych konsekwencji, a zwłaszcza nie mogą stanowić przyczyny uzasadniającej wypowiedzenie umowy o pracę lub jej rozwiązanie bez wypowiedzenia przez pracodawcę.</p>-->
<!--    <p><span class="bullet">5.4</span>Cofnięcie zgody nie wpływa na zgodność z prawem przetwarzania, którego dokonano na podstawie zgody przed jej wycofaniem.</p>-->
<!--    <p><span class="bullet">5.5</span>Dane dotyczące korzystania przez Ciebie ze strony <a href="www.misjaexpert.pl">www.misjaexpert.pl</a>, które uzyskujemy za pośrednictwem plików cookies przetwarzamy na podstawie Twojej zgody wyrażonej zgodnie z art. 173 ustawy – Prawo telekomunikacyjne.</p>-->
<!--    <p class="bold"><span class="bullet">6.0</span>Jak długo przetwarzane są twoje dane osobowe?</p>-->
<!--    <p><span class="bullet">6.1</span>Dla celów przeprowadzenia Akcji dane są przetwarzane do czasu jej zakończenia; po tym okresie Twoje dane będą przechowywane do końca 2026 r., tj. upływu okresu obowiązkowego przechowywania dokumentacji podatkowej oraz związanej z urządzaniem Akcji.</p>-->
<!--    <p><span class="bullet">6.2</span>W każdym przypadku Twoje dane osobowe będą przechowywane przez okres 5 lat od zakończenia innych czynności przetwarzania, ponieważ administrator musi być w stanie wykazać, że przetwarzanie Twoich danych obywało się zgodnie z przepisami o ochronie danych osobowych.</p>-->
<!--    <p class="bold"><span class="bullet">7.0</span>Kto jest odbiorcą twoich danych osobowych?</p>-->
<!--    <p class="non-padded">Twoje dane mogą być ujawniane innym podmiotom tylko wówczas, gdy jest to niezbędne do zrealizowania działań związanych z przeprowadzeniem Akcji. Są to zawsze podmioty wyspecjalizowane w danej dziedzinie, które świadczą profesjonalne usługi wspierające działalność Media Expert. Rodzaje podmiotów, którym mogą być ujawniane Twoje dane to: </p>-->
<!--    <p><span class="bullet">7.1</span>Wykonawca oraz inne firmy świadczące usługi IT – dzięki temu może być utrzymywane oprogramowanie służące do przeprowadzenia Akcji;</p>-->
<!--    <p><span class="bullet">7.2</span>inne podmioty, przy pomocy których przeprowadzana jest Akcja (podwykonawcy), np. firmy kurierskie, podmioty świadczące usługi księgowe oraz usługi prawne itp.</p>-->
<!--    <p class="non-padded">W każdym przypadku, gdy Twoje dane są ujawniane innym podmiotom, dzieję się to w ramach obowiązujących przepisów. Jeśli partnerzy lub usługodawcy przetwarzają Twoje dane w imieniu Media Expert, muszą zapewnić gwarancje wdrożenia rozwiązań technicznych i organizacyjnych, które będą chroniły Twoje dane osobowe i spełniały wymogi ogólnego Rozporządzenia o Ochronie Danych Osobowych (RODO).</p>-->
<!--    <p class="bold"><span class="bullet">8.0</span>Jakie masz prawa?</p>-->
<!--    <p><span class="bullet">8.1</span>Prawo ochrony danych osobowych daje Ci szereg praw, z których możesz skorzystać w dowolnej chwili. Twoje prawa obejmują:</p>-->
<!--    <p><span class="bullet">8.1.1</span>prawo dostępu do treści swoich danych osobowych; </p>-->
<!--    <p><span class="bullet">8.1.2</span>prawo do poprawiania danych;</p>-->
<!--    <p><span class="bullet">8.1.3</span>prawo do ograniczenia przetwarzania danych;</p>-->
<!--    <p><span class="bullet">8.1.4</span>prawo do żądania usunięcia danych;</p>-->
<!--    <p><span class="bullet">8.1.5</span>prawo do przenoszenia danych do innego administratora danych.</p>-->
<!--    <p><span class="bullet">8.2</span>Uprawnienia, o których mowa powyżej, możesz wykonywać poprzez kontakt pod adresem e-mail inspektor@me.pl lub pisząc na adres „Media Expert” TERG S.A., ul. Za Dworcem 1D, 77-400 Złotów, z dopiskiem „Dane osobowe”. </p>-->
<!--    <p><span class="bullet">8.3</span>Jeżeli uznasz, że w jakikolwiek sposób naruszone zostały reguły przetwarzania Twoich danych osobowych, masz prawo do złożenia skargi bezpośrednio do organu nadzoru (Prezes Urzędu Ochrony Danych Osobowych, ul. Stawki 2, 00-193 Warszawa). W ramach wykonania tego uprawnienia powinieneś podać pełny opis zaistniałej sytuacji oraz wskazać jakie działanie uznajesz za naruszające Twoje prawa lub wolności. Skargę należy złożyć bezpośrednio do organu nadzoru.</p>-->
    <a [routerLink]="['/']" class="return-anchor bottom"><i class="fa-solid fa-arrow-turn-down-left"></i>Powrót do strony głównej</a>
  </div>
</div>
